@charset "utf-8";

:root {
  --bg_blue: #eaf6fb;
  --bg_gray: #f5f5f5;
  --btn_blue1: #004690;
  --btn_blue2: #1867c0;
  --btn_green: #278806;
  --btn_white: #ffffff;
  --btn_orange: #ff8100;
  --btn_orange2: #fe5115;
}

// breakpoint
@mixin sp($ms: 767px) {
  @media only screen and (max-width: $ms) {
    @content;
  }
}
@mixin pc($ms: 768px) {
  @media only screen and (min-width: $ms) {
    @content;
  }
}

@mixin xs($min: 319px, $max: 767px) {
  @media only screen and (min-width: $min) and (max-width: $max) {
    @content;
  }
}

@mixin md($min: 768px, $max: 1023px) {
  @media only screen and (min-width: $min) and (max-width: $max) {
    @content;
  }
}

@mixin lg($min: 1024px, $max: 1439px) {
  @media only screen and (min-width: $min) and (max-width: $max) {
    @content;
  }
}

@mixin xl($min: 1550px) {
  @media only screen and (min-width: $min) {
    @content;
  }
}

@function vw($size, $viewport: 375) {
  $rate: calc(100 / $viewport);
  @return $rate * $size * 1vw;
}

*,
::after,
::before {
  box-sizing: border-box;
}

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: "Noto Sans JP", sans-serif;
  font-size: inherit;
  line-height: inherit;
}

form input[type="text"], form input[type="email"], form textarea, form select, form input[type="submit"] {
  font-family: "Noto Sans JP", sans-serif;
}

:root {
  --chikyu-back: #c4e6f550;
  --primary-color: #196fcb;
}

/*a {
  color: #0074ae;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #0056b3;
}*/

.chikyu-back {
  background: var(--chikyu-back);
}

.btn,
.btn svg {
  transition: all 0.3s ease 0s;
}

.bg_blue {
  background-color: var(--bg_blue);
}

.recaptcha_link {
  color: #ccc;
  font-size: 12px;
}

.grecaptcha-badge {
  visibility: hidden;
}

#recaptcha-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px 20px;
  background-color: #222222;
  color: #ffffff;
  border: 0;
  margin: 0;
  font-size: 1.2rem;
  gap: 15px;
  @include sp() {
    padding: 40px 10px;
    flex-direction: column-reverse;
  }

  img {
    margin-bottom: 10px;
  }

  .recaptcha {
    font-size: 12px;
    color: #cccccc;
  }
}

/*-------------------------------------------------------*/

.wpcf7 .screen-reader-response {
	position: absolute;
	overflow: hidden;
	clip: rect(1px, 1px, 1px, 1px);
	clip-path: inset(50%);
	height: 1px;
	width: 1px;
	margin: -1px;
	padding: 0;
	border: 0;
	word-wrap: normal !important;
}

.wpcf7 form .wpcf7-response-output {
	margin: 2em 0.5em 1em;
	padding: 0.2em 1em;
	border: 2px solid #00a0d2; /* Blue */
}

.wpcf7 form.init .wpcf7-response-output,
.wpcf7 form.resetting .wpcf7-response-output,
.wpcf7 form.submitting .wpcf7-response-output {
	display: none;
}

.wpcf7 form.sent .wpcf7-response-output {
	border-color: #46b450; /* Green */
}

.wpcf7 form.failed .wpcf7-response-output,
.wpcf7 form.aborted .wpcf7-response-output {
	border-color: #dc3232; /* Red */
}

.wpcf7 form.spam .wpcf7-response-output {
	border-color: #f56e28; /* Orange */
}

.wpcf7 form.invalid .wpcf7-response-output,
.wpcf7 form.unaccepted .wpcf7-response-output,
.wpcf7 form.payment-required .wpcf7-response-output {
	border-color: #ffb900; /* Yellow */
}

.wpcf7-form-control-wrap {
	position: relative;
}

.wpcf7-not-valid-tip {
	color: #dc3232; /* Red */
	font-size: 1em;
	font-weight: normal;
	display: block;
}

.use-floating-validation-tip .wpcf7-not-valid-tip {
	position: relative;
	top: -2ex;
	left: 1em;
	z-index: 100;
	border: 1px solid #dc3232;
	background: #fff;
	padding: .2em .8em;
	width: 24em;
}

.wpcf7-list-item {
	display: inline-block;
	margin: 0 0 0 1em;
}

.wpcf7-list-item-label::before,
.wpcf7-list-item-label::after {
	content: " ";
}

.wpcf7-spinner {
	visibility: hidden;
	display: inline-block;
	background-color: #23282d; /* Dark Gray 800 */
	opacity: 0.75;
	width: 24px;
	height: 24px;
	border: none;
	border-radius: 100%;
	padding: 0;
	margin: 0 24px;
	position: relative;
}

form.submitting .wpcf7-spinner {
	visibility: visible;
}

.wpcf7-spinner::before {
	content: '';
	position: absolute;
	background-color: #fbfbfc; /* Light Gray 100 */
	top: 4px;
	left: 4px;
	width: 6px;
	height: 6px;
	border: none;
	border-radius: 100%;
	transform-origin: 8px 8px;
	animation-name: spin;
	animation-duration: 1000ms;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
}

@media (prefers-reduced-motion: reduce) {
	.wpcf7-spinner::before {
		animation-name: blink;
		animation-duration: 2000ms;
	}
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

@keyframes blink {
	from {
		opacity: 0;
	}

	50% {
		opacity: 1;
	}

	to {
		opacity: 0;
	}
}

.wpcf7 [inert] {
	opacity: 0.5;
}

.wpcf7 input[type="file"] {
	cursor: pointer;
}

.wpcf7 input[type="file"]:disabled {
	cursor: default;
}

.wpcf7 .wpcf7-submit:disabled {
	cursor: not-allowed;
}

.wpcf7 input[type="url"],
.wpcf7 input[type="email"],
.wpcf7 input[type="tel"] {
	direction: ltr;
}

.wpcf7-reflection > output {
	display: list-item;
	list-style: none;
}

.wpcf7-reflection > output[hidden] {
	display: none;
}

/*-------------------------------------------------------*/

button,
input,
select,
optgroup,
textarea {
  color: #444444;
  font-size: 1.6em;
  font-weight: 500;
  line-height: 1.6;
}

html {
  font-size: 62.5%;
  box-sizing: border-box;
}

#wpadminbar {
  /* display: none; */
}

html {
  margin: 0px !important;
}

.-form {
  .wpcf7-form.sent {
    padding: 50px 8%;
    .input_group {
      display: none;
    }
  }
  .complete_message {
    h2 {
      padding: 0;
      border: none;
    }
  }
  * {
    font-family: "Noto Sans JP", sans-serif;
  }
  p {
    margin: 0;
    padding: 0;
    font-size: inherit;
  }
  img {
    max-width: 100%;
  }

  .masthead {
  }

  h1.title1 {
    font-size: 36px;
    line-height: 1.2;
    margin: 36px auto 10px;
  }

  .inquirycopy {
    font-size: min(23px, 5vw);
    line-height: 1.2;
    margin: 0 auto 36px;
  }

  h2 {
    text-align: center;
    font-weight: bold;
    margin-bottom: 20px;
    line-height: 1.7;
  }

  .two-col-area {
    margin-top: 52px;
    max-width: 960px;
    padding-bottom: 80px;
  }
  .document_image {
    margin: 0 0 50px;
  }

  h3 {
    display: inline-block;
    background: #fcee3b;
    padding: 12px 22px;
    margin: 20px 0 20px 0;

    &.request_body__title {
      display: block;
      text-align: center;
    }
  }

  .document_content,
  .document_reccomend {
    text-align: left;
  }

  .document_content {
    h3.request_body__title {
      background: #fcee3b;
      margin: 20px auto 40px;
    }

    .request_body__list {
      padding: 0;
      font-weight: bold;

      li {
        border-bottom: 1px dashed #cccccc;
        padding: 10px 0 10px 10px;
        line-height: 1;
        font-size: 15px;
      }
    }
  }

  .document_reccomend {
    margin: 40px 0 0;

    h3.request_body__title {
      background: initial;
      text-align: left;
      font-weight: bold;
      font-size: 21px;
      border-bottom: 1px solid #999;
      padding: 0 0 10px 10px;
      margin: 20px auto 30px;
    }

    .request_body__list {
      padding-left: 1.5em;
      font-weight: bold;
      list-style-type: disc;
      li {
        padding-bottom: 1em;
      }
    }
  }

  .chikyu-func {
    padding-left: 3rem;

    li {
      list-style: disc;
      font-weight: bold;
      margin: 8px 0;
    }
  }

  .description {
    font-size: inherit;
    color: inherit;
    line-height: inherit;
    margin-top: 30px;
    text-align: center;
  }

  .formarea,
  #colophon,
  .site-footer {
  }

  .copyright {
    margin-top: 20px;
  }
}

.blue-wrap {
  background: #dfe8f1;
  padding: 15px 15px 40px 15px;
}

.-form {
  .chikyu-form {
    padding: 25px 25px;

    /* margin: 15px 25px; */
    background: #fff;
  }

  select {
    width: 100%;
  }

  input,
  select,
  textarea {
    border-radius: 8px;
    border: 1px solid #ccc;
    max-width: 100%;
  }

  input::placeholder,
  textarea::placeholder {
    color: #9f9f9f;
  }

  label {
    font-size: 16px;
    color: #444;
  }

  .req {
    font-size: 12px;
    color: #e03f3f;
    padding: 6px 8px;
  }

  .chikyu-form input[type="submit"],
  .chikyu-result input[type="submit"] {
    margin-top: 16px;
    padding: 14px 16px;
    border-radius: 24px;
    display: inline-block;
    width: 100%;
    height: auto;
  }

  /*.privacy a {
    font-size: 14px;
    text-decoration: underline;
    color: #000;
    margin-top: 20px;
    display: inline-block;
  }*/

  .mwform-checkbox-field input,
  .mwform-radio-field input {
    margin-right: 0;
  }

  .mw_wp_form .horizontal-item + .horizontal-item {
    margin-left: 0;
  }
}

.con_list label:hover {
  cursor: pointer;
}

.customer_logo {
  padding: 0;
  margin: 60px 0;

  .logo {
    width: 33.3%;
    padding: 10px 15px;
  }
}

.form_youtube {
  max-width: 800px;
  margin: 50px auto -20px;
  width: 100%;
  height: 0;
  position: relative;
  padding-top: 30%;
  overflow: hidden;

  @include sp() {
    padding-top: 56.25%;
    width: auto;
    margin: 20px 15px -20px;
  }

  @include md() {
    padding-top: 55%;
  }

  @include lg() {
    padding-top: 40%;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
  }
}

.-form .col-md-6:nth-of-type(1) {
  -ms-flex: 0 0 47%;
  flex: 0 0 47%;
  max-width: 47%;
}

.page-template-page-caseform {
  .-form .two-col-area {
    max-width: 1200px;
    margin-bottom: 50px;
  }

  iframe {
    border: 0;
  }

  .-form {
    h1.title1 {
      font-size: 40px;
      font-weight: bold;
    }

    h2 {
      font-size: 26px;
      font-weight: bold;
      color: #1356a0;

      /* text-align: left; */
      line-height: 38px;
    }
  }

  .case-four-img {
    margin: 32px 0 40px;
  }

  .description {
    font-size: 20px;
    font-weight: bold;
  }

  .case-content {
    margin: 27px 0 50px;
    font-size: 18px;
  }

  .bottom-lead {
    font-size: 18px;
  }

  .list-icon {
    background: #1356a0;
    color: #fff;
    width: 23px;
    height: 23px;
    border-radius: 50%;
    font-size: 12px;
    position: relative;
    margin: 5px 10px 0 0;
    flex-basis: 23px;

    i {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      width: 11px;
      height: 11px;
    }
  }

  .list-body {
    flex-basis: calc(100% - 23px);
    line-height: 26px;
  }

  .case-content-list {
    margin: 0 0 20px;
  }

  #contact,
  .head_breadcrumb {
    display: none;
  }

  .link-area,
  #masthead.clone-nav,
  .footer-link-area,
  .head-sp-btn {
    display: none !important;
  }
}

.-form .formarea iframe {
  border: 0;
  height: 970px;
}

.page-template-page-inquiry_2col .-form .formarea iframe {
  height: 1020px;
}

@media (max-width: 767px) {
  .two-col-area .row > div {
    margin-bottom: 30px;
  }

  .-form {
    h1.title1 {
      font-size: 26px;
    }

    .two-col-area {
      margin-top: 32px;
    }

    > p {
      padding: 0 15px;
    }

    h1.title1 {
      font-size: min(26px, 6.5vw);
    }
  }

  #hero p {
    font-size: 14px;
  }

  .-formm .col-md-6:nth-of-type(1) {
    flex: 0 0 100%;
    min-width: 100%;
  }

}

.form-title {
  background-color: #ffffff;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  padding-top: 25px;
}

.-form .mw_wp_form,
.-form .wpcf7-form {
  background: #fff;
  padding: 20px 8%;

  .input_group_wrap {
    display: flex;
    max-width: 100%;
    gap: 20px;

    @include sp() {
      flex-direction: column;
      gap: 0;
    }

    .input_group {
      min-width: 0;
    }
  }

  .input_group {
    margin: 0 auto 10px;
    max-width: 100%;
    width: 100%;

    .input_label {
      font-size: 14px;
      line-height: 1.5;
      display: flex;
      padding-top: 5px;
      padding-bottom: 3px;
      font-weight: bold;

      @include sp() {
        line-height: 1.2;
      }
    }

    .input_description {
      font-size: 10px;
      display: inline-block;
    }
  }

  .input_group.submit-btn {
    margin: 30px auto 0;
  }

  input {
    max-width: 100%;
    font-size: 16px;
    border-radius: 4px;
    padding: 5px;
    font-weight: normal;
    &[type="number"] {
      width: 100%;
    }
    &[type="text"], &[type="tel"], &[type="email"] {
      width: 100%;
    }
  }

  textarea {
    font-size: 16px;
  }

  .button_radio {
  }

  label {
    cursor: pointer;
    font-size: 12px;
    display: flex;
    padding: 5px 10px;
    margin-bottom: 0;
  }

  input[type="radio"], .input_group:not(.privacy) input[type="checkbox"] {
    display: none;
  }

  input[type="checkbox"] {
    margin-right: 10px;
    transform: scale(1.5);

    // reset
    // margin: 0;
    // padding: 0;
    // background: none;
    // border: none;
    // border-radius: 0;
    // outline: none;
    // appearance: none;

    // // original
    // cursor: pointer;
    // width: 24px;
    // margin-right: 5px;
    // vertical-align: middle;
    // position: relative;
    // &::before, &::after {
    //   content: "";
    //   display: block;
    //   position: absolute;
    // }
    // &::before {
    //   background-color: #fff;
    //   border: 2px solid #000000;
    //   border-radius: 5px;
    //   width: 24px;/*チェックボックスの横幅*/
    //   height: 24px;/*チェックボックスの縦幅*/
    //   transform: translateY(-50%);
    //   top: 50%;
    //   left: 0px;
    // }
    // &:focus-visible::before {
    //   // border: 2px solid #000;
    //   outline: -webkit-focus-ring-color auto 2px;
    //   outline-offset: 1px;
    // }
    // &::after {
    //   border-bottom: 3px solid #278806;/*チェックの太さ*/
    //   border-left: 3px solid #278806;/*チェックの太さ*/
    //   opacity: 0;/*チェック前は非表示*/
    //   height: 10px;/*チェックの高さ*/
    //   width: 15px;/*チェックの横幅*/
    //   transform: rotate(-45deg);
    //   top: -7px;/*チェック時の位置調整*/
    //   left: 5px;/*チェック時の位置調整*/
    // }
    // &:checked::after {
    //   opacity: 1;
    // }
  }

  .mwform-radio-field {
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 0 5px 5px 0;

    &:hover {
      background-color: #fff176;
    }

    &:has(:checked) {
      background-color: rgba(255, 255, 0, 0.2);
    }
  }

  a[href*="//"]
  {
    text-decoration: underline;
  }

  button[type="submit"].btn_orange,
  input[type="submit"].btn_orange {
    border: 1px solid var(--btn_orange2);
    font-size: 18px;
    background-color: var(--btn_orange2);
    color: #fff;
    padding: 8px 20px;
    transition: all 0.3s;
    margin: auto;
    display: block;
    width: min(300px, 95%);

    &:hover {
      background-color: #fff;
      color: var(--btn_orange2);
    }
  }

  button[type="submit"].btn_blue,
  input[type="submit"].btn_blue {
    border: 1px solid var(--btn_blue1);
    border-radius: 10px;
    font-size: 18px;
    background-color: var(--btn_blue1);
    color: #fff;
    padding: 8px 20px;
    transition: all 0.3s;
    margin: auto;
    display: block;
    width: min(300px, 95%);

    &:hover {
      background-color: #fff;
      color: var(--btn_blue1);
    }
  }

  button[type="submit"].btn_green,
  input[type="submit"].btn_green {
    border: 1px solid var(--btn_green);
    font-size: 18px;
    background-color: var(--btn_green);
    color: #fff;
    padding: 8px 20px;
    transition: all 0.3s;
    margin: auto;
    display: block;
    width: min(300px, 95%);

    &:hover {
      background-color: #fff;
      color: var(--btn_green);
    }
  }

  .req {
    color: #e03f3f;
    padding: 0;
    font-size: 16px;
  }
}
.wpcf7 {
  .wpcf7-form {
    .wpcf7-form-control-wrap {
      display: block;
      font-weight: normal;
    }

    .wpcf7-radio .wpcf7-list-item, .input_group:not(.privacy) .wpcf7-checkbox .wpcf7-list-item {
      display: inline-block;
      border: 1px solid #ccc;
      border-radius: 5px;
      margin: 0 5px 5px 0;

      input {
        display: none;
      }
      &:hover {
        background-color: #fff176;
      }

      &:has(:checked) {
        background-color: rgba(255, 255, 0, 0.2);
      }
    }
    .wpcf7-acceptance .wpcf7-list-item {
      margin: 0;
    }
    .wpcf7-not-valid-tip {
      font-size: 0.6em;
    }
  }
  form.sent .wpcf7-response-output {
    display: none;
  }
}

main.thanks-content {
  padding: 20px 20px 80px;
}

.chikyu-complete {
  padding-block: 4rem;
}

.video-title {
  text-align: center;
  font-weight: bold;
  padding-bottom: 20px;
}

.privacy-image {
  display: flex;
  justify-content: flex-end;
}

header {
  position: relative;
}

header.__shadow {
  box-shadow: unset;
}

header .w1400 .logo-area a img {
  height: 40px;
  margin-left: 210px;
  @include sp() {
    margin-left: 0;
  }
  @include md() {
    margin-left: 0;
  }
  @include lg() {
    margin-left: 35px;
  }
}

.title1 {
  margin: 0 auto 0.7em auto !important;
}

.error-message {
  color: #e03f3f;
  font-size: 12px;
  display: none;
}

.input_group.required input:focus:not([type="checkbox"]),
.input_group.required textarea:focus,
.wpcf7-not-valid {
  outline: 1px solid #e03f3f;
}

.input_group.filled.required input:not([type="checkbox"]),
.input_group.filled.required textarea {
  border-color: #00ff00;
  outline-color: #00ff00;
}

.input_group.email_input_group input.invalid:not([type="checkbox"]),
.input_group.phone_input_group input.invalid,
.wpcf7-not-valid {
  border-color: #e03f3f !important;
  outline-color: #e03f3f !important;
}

.site-header {
  display: none;
}

.page-template-page-trial_2col {
  .two-col-area {
    padding-bottom: 0;
  }
}
